import React from 'react'
import QRCodeGenerator from '../components/QRCodeGenerator'
const Payment = () => {
  return (
    <div>
        <QRCodeGenerator/>
    </div>
  )
}

export default Payment