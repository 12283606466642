import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavDropdown,
  Button,
  Offcanvas,
  Dropdown,
} from "react-bootstrap";
import Logo from "../../images/blackorcade.png"; // Make sure this path is correct
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const LMSHeader = () => {
  const [show, setShow] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate(); // Add useNavigate hook for navigation

  useEffect(() => {
    // Check if user and token exist in localStorage
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    if (token && user) {
      setIsLoggedIn(true);
      setUserName(user.name); // Assuming the user object has a 'name' field
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage data
    navigate("/LMS/login"); // Navigate to login page
    window.location.reload(); // Reload the page to reflect logout
  };

  return (
    <div style={{ backgroundColor: "black" }} className="sticky-top">
      <Container className="d-flex align-items-center justify-content-between p-3">
        <div className="d-flex align-items-center h-100 w-50 p-2">
          <a href="/LMS">
            {" "}
            <img
              src={Logo}
              alt="OrcadeHub Logo"
              style={{ height: "60px", marginRight: "10px" }}
              className="oh-logo"
            />
          </a>
          <h1
            className="mb-0 orcadehub-title"
            style={{
              color: "#6A0DAD",
              fontWeight: "bolder",
              whiteSpace: "nowrap",
            }}
          >
            ORCADE HUB
          </h1>
        </div>

        {/* Show the normal navigation for screens larger than 768px */}
        <div className="d-none d-md-flex align-items-center justify-content-end w-50 gap-3">
          <NavDropdown
            title="Tutorials"
            id="nav-dropdown"
            className="text-white"
          >
            <NavDropdown.Item href="/LMS">Video Tutorials</NavDropdown.Item>
            <NavDropdown.Item href="/LMS">Docs</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/LMS" className="text-white">
            CodeHub
          </Nav.Link>
          <Nav.Link href="/LMS/certificates" className="text-white">
            Certificates
          </Nav.Link>
          {isLoggedIn ? (
            <Dropdown align="end">
              <Dropdown.Toggle
                className="profile-icon"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className="text-primary ms-2" style={{ fontSize: "24px" }}>
                  {userName[0].toUpperCase()}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="/LMS/dashboard">Dashboard</Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <>
              <Button
                href="/LMS/login"
                className="me-2 border"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#6a0dad",
                  width: "10rem",
                  height: "2.5rem",
                }}
              >
                Login
              </Button>
              <Button
                href="/LMS/signup"
                className="border"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "#6a0dad",
                  width: "10rem",
                  height: "2.5rem",
                }}
              >
                Get Started
              </Button>
            </>
          )}
        </div>

        {/* Show a menu button for screens smaller than 768px */}
        <div className="d-flex d-md-none align-items-center justify-content-end">
          <Button
            variant="outline-light"
            onClick={handleShow}
            className="border-0"
          >
            <FaBars style={{ fontSize: "24px", color: "#fff" }} />
          </Button>
        </div>
      </Container>

      {/* Offcanvas for the mobile menu */}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavDropdown
            title="Tutorials"
            id="nav-dropdown-mobile"
            className="mb-3"
          >
            <NavDropdown.Item href="/video-tutorials">
              Video Tutorials
            </NavDropdown.Item>
            <NavDropdown.Item href="/docs">Docs</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/codehub" className="mb-3">
            CodeHub
          </Nav.Link>
          <Nav.Link href="/LMS/certificates" className="mb-3">
            Certificates
          </Nav.Link>
          {isLoggedIn ? (
            <div className="d-flex flex-column align-items-start">
              <span className="mb-3">{userName}</span>
              <Dropdown>
                <Dropdown.Toggle
                  className="profile-icon mb-3"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="text-primary ms-2" style={{ fontSize: "24px"}}>
                    {userName[0].toUpperCase()}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/LMS/dashboard">Dashboard</Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <>
              <Button
                variant="warning"
                href="/LMS/login"
                className="mb-3 w-100"
              >
                Login
              </Button>
              <Button variant="warning" href="/LMS/signup" className="w-100">
                Get Started
              </Button>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default LMSHeader;
