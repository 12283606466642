import React from "react";
// import { Link } from "react-router-dom";
import Testimonials from '../components/Testimonials';
import Companies from '../components/Companies';
import './Home.css'; // Import the CSS file
import FreeCard from "../components/FreeCard";
const Home = () => {
  return (
    <div style={{ textAlign: "center", fontWeight: "bold" }}>
     <FreeCard/>
      <Companies/>
      <Testimonials/>
    </div>
  );
};

export default Home;
