import React from "react";
import PythonImage from "../images/python.jpeg"; // replace with the actual image path
import DataStructuresImage from "../images/ds.png"; // replace with the actual image path
import JavaImage from '../images/java.png'
import MernImage from "../images/mern.jpg";

const UpcomingEvents = () => {
  // const calculateDiscountPrice = (originalPrice, discountPercentage) => {
  //   return originalPrice - (originalPrice * discountPercentage) / 100;
  // };

 

  return (
    <div className="container mt-5">
      <h2 className="text-center" style={{ color: "#6A0DAD", fontWeight: "bold" }}>Upcoming Events</h2>
      <div className="row mt-4">
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <img src={MernImage} className="card-img-top" alt="Python Course" height={300} />
            <div className="card-body">
              <h5 className="card-title">MERN Stack Course</h5>
              <p className="card-text">Course Objective: Learn Full Stack Web Development using MERN Stack from basics to advanced.</p>
              <p className="card-text">Start Date: 15th September 2024 (Next batch oct 15)</p>
              <p className="card-text">Price: ₹999 per month</p>
              <p className="card-text">Duration: 6 Months </p>
              {/* <h6 className="card-text">4 months Training + 2 Months Internship</h6> */}
              {/* <p className="card-text" style={{ fontWeight: "bold", color: "#ff0000" }}>Offer Price: ₹{calculateDiscountPrice(499, 90).toFixed(2)} ( ₹perday:2.49)</p> */}
              <p className="card-text">24/7 Assistance</p>
              <a href="https://forms.gle/LNwh2qGRHRcCzDpe6"> <button className="btn" style={{ backgroundColor: "#6A0DAD", color: "white" }}>Buy This Course</button></a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <img src={DataStructuresImage} className="card-img-top" alt="Data Structures Course" height={300} />
            <div className="card-body">
              <h5 className="card-title">Data Structures Course</h5>
              <p className="card-text">Course Objective: Understand data structures and their applications.</p>
              <p className="card-text">Start Date: 12th September 2024</p>
              <p className="card-text">Price: ₹499</p>
              <p className="card-text">Duration: 3 days </p>
              <p className="card-text" style={{ fontWeight: "bold", color: "#ff0000" }}>Offer Price: FREE ( ₹5 certification charges)</p>
              <p className="card-text">24/7 Assistance</p>
               <a href="https://forms.gle/Dn9DCva97gRb8UC4A"> <button className="btn" style={{ backgroundColor: "#6A0DAD", color: "white" }}>Buy This Course</button></a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <img src={JavaImage} className="card-img-top" alt="Java Course" height={300} />
            <div className="card-body">
              <h5 className="card-title">Java Course</h5>
              <p className="card-text">Course Objective: Learn Java programming thoroughly, from basic principles to advanced concepts.</p>
              <p className="card-text">Start Date: 20th September 2024</p>
              <p className="card-text">Price: ₹499</p>
              <p className="card-text">Duration: 15 days </p>
              <p className="card-text" style={{ fontWeight: "bold", color: "#ff0000" }}>Offer Price: ₹75 ( ₹perday:5)</p>
              <p className="card-text">24/7 Assistance</p>
              <a href="https://forms.gle/T596Yg9yxLJ1XtRz7"> <button className="btn" style={{ backgroundColor: "#6A0DAD", color: "white" }}>Buy This Course</button></a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card shadow-sm">
            <img src={PythonImage} className="card-img-top" alt="Java Course" height={300} />
            <div className="card-body">
              <h5 className="card-title">Python Course</h5>
              <p className="card-text">Course Objective: Master Python programming from foundational concepts to advanced techniques.</p>
              <p className="card-text">Start Date: 20th September 2024</p>
              <p className="card-text">Price: ₹499</p>
              <p className="card-text">Duration: 15 days </p>
              <p className="card-text" style={{ fontWeight: "bold", color: "#ff0000" }}>Offer Price: ₹75 ( ₹perday:5)</p>
              <p className="card-text">24/7 Assistance</p>
              <a href="https://forms.gle/T596Yg9yxLJ1XtRz7"> <button className="btn" style={{ backgroundColor: "#6A0DAD", color: "white" }}>Buy This Course</button></a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4" style={{textAlign:"center",fontWeight:'bold'}}>
        <h5>Note:</h5>
        <ul>
          <li>We do not follow recorded classes. All sessions are live.</li>
          <li>Daily online classes for</li>
          <li>Hands-on projects and assignments.</li>
          <li style={{color:'#6A0DAD',fontSize:'20px'}}>Industry-recognized certification upon completion.</li>
          <li>Mentorship and career guidance from experts.</li>
        </ul>
      </div>
    </div>
  );
};

export default UpcomingEvents;
