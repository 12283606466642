import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";

const Sidebar = ({ role }) => {
  const [selectedContent, setSelectedContent] = useState("Under Maintenance");

  const handleSelect = (content) => {
    setSelectedContent(content);
  };

  return (
    <div className="row">
      <div className="col-3 border border-3 border-dark">
        <Accordion defaultActiveKey="1" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>My Courses</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <a href="#" onClick={() => handleSelect("Course 1 Content")}>
                    Course 1
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleSelect("Course 2 Content")}>
                    Course 2
                  </a>
                </li>
                {/* Add more courses dynamically */}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Certification Courses</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      handleSelect("Frontend Development with React Content")
                    }
                  >
                    Frontend Development with React
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      handleSelect("Backend Development with Express and MongoDB Content")
                    }
                  >
                    Backend Development with Express and MongoDB
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleSelect("SQL and MySQL Content")}>
                    SQL and MySQL
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleSelect("Python Programming Content")}
                  >
                    Python Programming
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleSelect("Java Programming Content")}>
                    Java Programming
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleSelect("Data Structures Content")}
                  >
                    Data Structures
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleSelect("MERN Stack Development Content")}
                  >
                    MERN Stack Development
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      handleSelect(
                        "Full Stack Web Development Content (HTML, CSS, JS, Bootstrap, MERN)"
                      )
                    }
                  >
                    Full Stack Web Development
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleSelect("Virtual Internships Content")}
                  >
                    Virtual Internships
                  </a>
                </li>
                {/* Add more certifications as needed */}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Assignments</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Assignment 1 - Due: <strong>2024-10-01</strong>
                </li>
                <li>
                  Assignment 2 - Due: <strong>2024-10-05</strong>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Payment History</Accordion.Header>
            <Accordion.Body>
              <a href="/LMS/dashboard">View Payment History</a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Profile Settings</Accordion.Header>
            <Accordion.Body>
              <a href="/LMS/dashboard">Edit Profile</a>
              <br />
              <a href="/LMS/dashboard">Change Password</a>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Support</Accordion.Header>
            <Accordion.Body>
              <a href="/LMS/dashboard">Contact Support</a>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="col-9 bg text-center">
        <h1>{selectedContent}</h1>
      </div>
    </div>
  );
};

export default Sidebar;
