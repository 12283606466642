import React, { useState } from 'react';
import axios from 'axios';
import { FaWhatsapp } from 'react-icons/fa';
import config from '../../config';

const QRCodeGenerator = () => {
  const [price, setPrice] = useState('');
  const [installment, setInstallment] = useState('');
  const [qrCode, setQrCode] = useState(null);
  const [error, setError] = useState('');

  const upiId = 'azzuzie@ybl';
  const payeeName = 'ORCADEHUB';
  const invoiceId = '1001';

  const baseURL =
    process.env.NODE_ENV === "development"
      ? config.LOCAL_BASE_URL
      : config.BASE_URL;

  const generateQRCode = async () => {
    // Check if any required field is missing
    if (!price || (price === '2000' && !installment)) {
      setError('All fields are required');
      return;
    }

    setError('');

    try {
      // Determine the final amount based on the selected price
      let finalAmount;
      if (price === '2000') {
        finalAmount = installment;  // Use installment amount for MERN Stack
      } else if (price === 'p'  || price === 'ds') {
        finalAmount = '100';  // Set final amount to 75 for Python, Java, and Data Structures
      }
      else if (price === 'j') {
        finalAmount = '75';  // Set final amount to 75 for Python, Java, and Data Structures
      }

      const response = await axios.post(`${baseURL}generateQRCode`, {
        upiId,
        amount: finalAmount,
        invoiceId,
        payeeName: payeeName,
      });

      setQrCode(response.data.qrCodeData);
      console.log(response); // Set the QR code data in the state
    } catch (err) {
      console.error('Error generating QR code:', err);
      setError('Failed to generate QR code. Please try again.');
    }
  };

  // Handle WhatsApp Click
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/917093012101`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2>Generate UPI Payment QR Code</h2>

      {/* Dropdown to select price */}
      <div style={{ marginBottom: '20px' }}>
        <select
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          style={{ padding: '10px', margin: '10px', width: '250px' }}
        >
          <option value="" disabled>Select Price</option>
          <option value="2000">MERN Stack (₹6000)</option>
          <option value="p">Python (₹100)</option>
          <option value="ds">Data Structures (₹100)</option>
          <option value="j">Java (₹75)</option>
        </select>
      </div>

      {/* Conditional rendering of installment dropdown */}
      {price === '2000' && (
        <div style={{ marginBottom: '20px' }}>
          <select
            value={installment}
            onChange={(e) => setInstallment(e.target.value)}
            style={{ padding: '10px', margin: '10px', width: '250px' }}
          >
            <option value="" disabled>Select Installment</option>
            <option value="2000">1st Installment (₹2000)</option>
            <option value="2000">2nd Installment (₹2000)</option>
            <option value="2000">3rd Installment (₹2000)</option>
          </select>
        </div>
      )}

      {/* Button to generate QR code */}
      <button
        onClick={generateQRCode}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
        }}
      >
        Generate QR Code
      </button>

      {/* Display error if exists */}
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}

      {/* Display the QR Code if generated */}
      {qrCode && (
        <div style={{ marginTop: '20px' }} className='text-center'>
          <h3>Scan to Pay</h3>
          <img src={qrCode} alt="UPI QR Code" />
          <br />
          
          <strong>After payment, send screenshot on WhatsApp</strong>
          <br />
          
          {/* WhatsApp button with icon */}
          <button
            className="whatsapp-button"
            onClick={handleWhatsAppClick}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
              backgroundColor: '#25D366', // WhatsApp green color
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              marginTop: '10px',
              gap: '8px', // space between icon and text
            }}
          >
            <FaWhatsapp size={20} />
            Click Here to Send
          </button>
        </div>
      )}
    </div>
  );
};

export default QRCodeGenerator;
