import React, { useState } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import './Login.css'; // Import the CSS file
import config from '../../config';
// import { login } from "../../redux/userSlice";
// import { useDispatch } from 'react-redux';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  // const dispatch = useDispatch(); // Initialize useDispatch


  const baseURL =
    process.env.NODE_ENV === "development"
      ? config.LOCAL_BASE_URL
      : config.BASE_URL;

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please enter a valid email and password!");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email format!");
      return;
    }

    setLoading(true); 

    try {
      const response = await axios.post(
        `${baseURL}loginUser`,
        { email, password },
        { headers: { "Content-type": "application/json" } }
      );

      const token = response.data.token;
      const user = response.data.user; // Assuming user data is also returned
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user)); // Store user data

      toast.success("Login successful!");
      // dispatch(login(user));

      setTimeout(() => {
        navigate("/LMS/dashboard");
        window.location.reload(); // Reload the page after navigation
      }, 0);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Invalid email or password!");
        } else {
          toast.error("Server error, please try again.");
        }
      } else {
        toast.error("Network error, please try again.");
      }
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="login">
      <h3>Login to your account</h3>
      <form onSubmit={handleLogin}>
        <TextField
          id="email"
          name="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          autoComplete="email"
          fullWidth
        />
        <TextField
          id="enter-password"
          name="password"
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          fullWidth
        />
        <Button type="submit" variant="contained" disabled={loading}>
          {loading ? <CircularProgress size={24} color="inherit" /> : "Login"}
        </Button>
      </form>
      <div>
        <Link to="/LMS/signup">Don't have an account? Sign Up</Link>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default Login;
