// Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import Sidebar from '../components/Sidebar'; // Import the Sidebar component

const Dashboard = ({ user }) => {
  const [role, setRole] = useState(0); // Default role is 0 (User)
  const navigate = useNavigate(); // Hook to navigate programmatically

  // Simulate fetching user role from login info
  useEffect(() => {
    if (!user) {
      // If user is not logged in, redirect to login page
      navigate('/LMS/login');
    } else {
      setRole(user.role); // Set role from the user object (0 for user, 1 for admin)
    }
  }, [user, navigate]); // Add navigate to the dependency array

  return (
    <div className="dashboard-container">
      <Sidebar role={role} />
      {/* <div className="main-content">
        <h1>Welcome to the {role === 0 ? 'User' : 'Admin'} Dashboard</h1>
        <p>
          {role === 0
            ? 'Here you can view your enrolled courses, fee payment history, and more. test'
            : 'As an admin, you can generate certificates, manage users, and oversee reports.test'}
        </p>
      </div> */}
    </div>
  );
};

export default Dashboard;
