import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Courses from "./pages/Courses";
import Internships from "./pages/Internships";
import LMS from "./pages/LMS";
import Login from "./LMS/components/Login";
import LMSHeader from "./LMS/components/LMSHeader";
import Certi from "./LMS/pages/Certi";
import Signup from "./LMS/components/Signup";
import Payment from "./LMS/pages/Payment";
import Dashboard from "./LMS/pages/Dashboard"
const App = () => {
  const user = JSON.parse(localStorage.getItem('user')) || null;
  const location = useLocation();
  return (
    <>
      {/* Render the appropriate header based on the route */}
      {location.pathname.startsWith("/LMS") ? <LMSHeader /> : <Header />}

      {/* Main App Routes */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/courses" element={<Courses />} />
        {/* <Route exact path="/certificate" element={<Certificate />} /> */}
        <Route exact path="/internships" element={<Internships />} />

        {/* LMS Routes */}
        <Route exact path="/LMS" element={<LMS />} />
        <Route exact path="/LMS/login" element={<Login />} />
        <Route exact path="/LMS/signup" element={<Signup />} />
        <Route exact path="/LMS/certificates" element={<Certi />} />
        <Route exact path="/LMS/Payment" element={<Payment />} />
        <Route exact path="/LMS/dashboard" element={<Dashboard user={user}/>} />
      </Routes>

      {/* Render Footer for non-LMS routes */}
      {!location.pathname.startsWith("/LMS") && <Footer />}
    </>
  );
};

export default App;

// import React from "react";
// import axios from "axios";
// import Stripe from "react-stripe-checkout";

// const App = () => {
//  const handleToken = (totalAmount, token) => {
//     try {
//       axios.post("http://localhost:3300/api/stripe/pay", {
//         token: token.id,
//         amount: totalAmount,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const tokenHandlr = (token) => {
//     handleToken(100, token);
//   };
//   return (
//     <div>
//       <Stripe
//         stripeKey="pk_test_51PnwqUSFGzsF8oYAhCtL8Zaxh8PpRGRJ2RthdRMDrqvf0qd9qErOiL8kUmyBksDmuS08TYOFIyagUpk8TOSYMKBS00khQdXYIl"
//         token={tokenHandlr}
//       />
//     </div>
//   );
// };

// export default App;
